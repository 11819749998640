<script setup>
import { ref, onMounted, watch } from 'vue';
import debounce from '@/utils/debounce';
import HrbrInput from '@components/ui/HrbrInput.vue';

const props = defineProps({
  value: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['input', 'isValidUrlChanged']);

let faviconUrl = ref(null);
let validUrl = ref(false);
let errorMessage = ref('');
let url = ref(props.value);
let urlOrigin = '';

onMounted(() => {
  validateURL();
  getFavIcon();
});

watch(validUrl, (currentValue, oldValue) => {
  emit('isValidUrlChanged', currentValue);
});

const onInputHandler = async () => {
  validateURL();
  // we need to invalidate icon right away if url is invalid
  if (!validUrl.value) {
    faviconUrl.value = null;
  }
  getFavIconDebounced();
};

const onImageError = async () => {
  faviconUrl.value = null
};

const getFavIcon = async () => {
  faviconUrl.value = null;
  if (!validUrl.value) {
    return;
  }
  faviconUrl.value = `${urlOrigin}/favicon.ico`;
};

const getFavIconDebounced = debounce(async (event) => {
  await getFavIcon();
}, 1500);

const validateURL = () => {
  if (!url.value) {
    return;
  }

  validUrl.value = false;
  try {
    const urlObj = new URL(url.value);
    validUrl.value = true;
    urlOrigin = urlObj.origin;
    errorMessage.value = null;
    if (!['http:', 'https:'].includes(urlObj.protocol)) {
      validUrl.value = false;
      errorMessage.value = 'Invalid URL: An explicit scheme (such as https) must be provided';
    }
  } catch (e) {
    validUrl.value = false;
    errorMessage.value = 'Invalid URL';
    if (!url.value.startsWith('http')) {
      errorMessage.value = 'Invalid URL: An explicit scheme (such as https) must be provided';
    }
  }
};
</script>

<template>
  <div class="vue-url-input">
    <div class="wrapper">
      <hrbr-input
        :class="{
          'favicon-found': faviconUrl,
        }"
        :error-message="errorMessage"
        v-bind="$attrs"
        v-model.trim="url"
        placeholder="https://"
        @input="
          $emit('input', $event);
          onInputHandler();
        ">
        <template #left-icon>
          <!-- favicon lives here -->
          <div 
            v-if="faviconUrl" 
            class="favicon-ctn">
            <img
              :src="faviconUrl"
              @error="
                onImageError()
              "
              class="favicon"
              alt="favicon"
            >
          </div>
          <i v-else class="fa-light fa-link"></i>
        </template>
      </hrbr-input>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.vue-url-input {
  width: 100%;
  flex-shrink: 0;
}
.wrapper {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .favicon-ctn {
    display: flex;
    width: 30px;
  }
}
</style>
